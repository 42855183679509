exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-allergener-index-js": () => import("./../../../src/pages/allergener/index.js" /* webpackChunkName: "component---src-pages-allergener-index-js" */),
  "component---src-pages-allergitester-js": () => import("./../../../src/pages/allergitester.js" /* webpackChunkName: "component---src-pages-allergitester-js" */),
  "component---src-pages-artiklar-index-js": () => import("./../../../src/pages/artiklar/index.js" /* webpackChunkName: "component---src-pages-artiklar-index-js" */),
  "component---src-pages-bekraftelse-js": () => import("./../../../src/pages/bekraftelse.js" /* webpackChunkName: "component---src-pages-bekraftelse-js" */),
  "component---src-pages-blodprov-js": () => import("./../../../src/pages/blodprov.js" /* webpackChunkName: "component---src-pages-blodprov-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-fakta-js": () => import("./../../../src/pages/fakta.js" /* webpackChunkName: "component---src-pages-fakta-js" */),
  "component---src-pages-foretagshalsovard-artiklar-js": () => import("./../../../src/pages/foretagshalsovard/artiklar.js" /* webpackChunkName: "component---src-pages-foretagshalsovard-artiklar-js" */),
  "component---src-pages-foretagshalsovard-index-js": () => import("./../../../src/pages/foretagshalsovard/index.js" /* webpackChunkName: "component---src-pages-foretagshalsovard-index-js" */),
  "component---src-pages-foretagshalsovard-kontakt-js": () => import("./../../../src/pages/foretagshalsovard/kontakt.js" /* webpackChunkName: "component---src-pages-foretagshalsovard-kontakt-js" */),
  "component---src-pages-halsokontroll-js": () => import("./../../../src/pages/halsokontroll.js" /* webpackChunkName: "component---src-pages-halsokontroll-js" */),
  "component---src-pages-halsokontroller-js": () => import("./../../../src/pages/halsokontroller.js" /* webpackChunkName: "component---src-pages-halsokontroller-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infor-provtagningen-js": () => import("./../../../src/pages/infor-provtagningen.js" /* webpackChunkName: "component---src-pages-infor-provtagningen-js" */),
  "component---src-pages-jobb-js": () => import("./../../../src/pages/jobb.js" /* webpackChunkName: "component---src-pages-jobb-js" */),
  "component---src-pages-kalkylatorer-agglossning-js": () => import("./../../../src/pages/kalkylatorer/agglossning.js" /* webpackChunkName: "component---src-pages-kalkylatorer-agglossning-js" */),
  "component---src-pages-kalkylatorer-bmi-js": () => import("./../../../src/pages/kalkylatorer/bmi.js" /* webpackChunkName: "component---src-pages-kalkylatorer-bmi-js" */),
  "component---src-pages-kalkylatorer-fritt-testosteron-js": () => import("./../../../src/pages/kalkylatorer/fritt-testosteron.js" /* webpackChunkName: "component---src-pages-kalkylatorer-fritt-testosteron-js" */),
  "component---src-pages-kalkylatorer-index-js": () => import("./../../../src/pages/kalkylatorer/index.js" /* webpackChunkName: "component---src-pages-kalkylatorer-index-js" */),
  "component---src-pages-kalkylatorer-progesteron-ostrogen-ratio-js": () => import("./../../../src/pages/kalkylatorer/progesteron-ostrogen-ratio.js" /* webpackChunkName: "component---src-pages-kalkylatorer-progesteron-ostrogen-ratio-js" */),
  "component---src-pages-kampanjer-js": () => import("./../../../src/pages/kampanjer.js" /* webpackChunkName: "component---src-pages-kampanjer-js" */),
  "component---src-pages-klagomal-js": () => import("./../../../src/pages/klagomal.js" /* webpackChunkName: "component---src-pages-klagomal-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-markorer-index-js": () => import("./../../../src/pages/markorer/index.js" /* webpackChunkName: "component---src-pages-markorer-index-js" */),
  "component---src-pages-mottagningar-index-js": () => import("./../../../src/pages/mottagningar/index.js" /* webpackChunkName: "component---src-pages-mottagningar-index-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-partners-index-js": () => import("./../../../src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-presentkort-index-js": () => import("./../../../src/pages/presentkort/index.js" /* webpackChunkName: "component---src-pages-presentkort-index-js" */),
  "component---src-pages-presentkort-skapa-js": () => import("./../../../src/pages/presentkort/skapa.js" /* webpackChunkName: "component---src-pages-presentkort-skapa-js" */),
  "component---src-pages-priser-js": () => import("./../../../src/pages/priser.js" /* webpackChunkName: "component---src-pages-priser-js" */),
  "component---src-pages-redirect-js": () => import("./../../../src/pages/redirect.js" /* webpackChunkName: "component---src-pages-redirect-js" */),
  "component---src-pages-sa-fungerar-det-js": () => import("./../../../src/pages/sa-fungerar-det.js" /* webpackChunkName: "component---src-pages-sa-fungerar-det-js" */),
  "component---src-pages-sjalvtester-index-js": () => import("./../../../src/pages/sjalvtester/index.js" /* webpackChunkName: "component---src-pages-sjalvtester-index-js" */),
  "component---src-pages-sok-js": () => import("./../../../src/pages/sok.js" /* webpackChunkName: "component---src-pages-sok-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-symtom-index-js": () => import("./../../../src/pages/symtom/index.js" /* webpackChunkName: "component---src-pages-symtom-index-js" */),
  "component---src-pages-tester-index-js": () => import("./../../../src/pages/tester/index.js" /* webpackChunkName: "component---src-pages-tester-index-js" */),
  "component---src-pages-vardtjanster-js": () => import("./../../../src/pages/vardtjanster.js" /* webpackChunkName: "component---src-pages-vardtjanster-js" */),
  "component---src-pages-varukorg-dela-js": () => import("./../../../src/pages/varukorg/dela.js" /* webpackChunkName: "component---src-pages-varukorg-dela-js" */),
  "component---src-pages-varukorg-erbjudanden-js": () => import("./../../../src/pages/varukorg/erbjudanden.js" /* webpackChunkName: "component---src-pages-varukorg-erbjudanden-js" */),
  "component---src-pages-varukorg-index-js": () => import("./../../../src/pages/varukorg/index.js" /* webpackChunkName: "component---src-pages-varukorg-index-js" */),
  "component---src-pages-varumarken-index-js": () => import("./../../../src/pages/varumarken/index.js" /* webpackChunkName: "component---src-pages-varumarken-index-js" */),
  "component---src-pages-villkor-cookies-js": () => import("./../../../src/pages/villkor/cookies.js" /* webpackChunkName: "component---src-pages-villkor-cookies-js" */),
  "component---src-pages-villkor-index-js": () => import("./../../../src/pages/villkor/index.js" /* webpackChunkName: "component---src-pages-villkor-index-js" */),
  "component---src-pages-villkor-integritetspolicy-js": () => import("./../../../src/pages/villkor/integritetspolicy.js" /* webpackChunkName: "component---src-pages-villkor-integritetspolicy-js" */),
  "component---src-pages-villkor-kopvillkor-js": () => import("./../../../src/pages/villkor/kopvillkor.js" /* webpackChunkName: "component---src-pages-villkor-kopvillkor-js" */),
  "component---src-templates-allergen-group-js": () => import("./../../../src/templates/allergen_group.js" /* webpackChunkName: "component---src-templates-allergen-group-js" */),
  "component---src-templates-allergen-js": () => import("./../../../src/templates/allergen.js" /* webpackChunkName: "component---src-templates-allergen-js" */),
  "component---src-templates-article-group-js": () => import("./../../../src/templates/article_group.js" /* webpackChunkName: "component---src-templates-article-group-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-location-group-js": () => import("./../../../src/templates/location_group.js" /* webpackChunkName: "component---src-templates-location-group-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-marker-group-js": () => import("./../../../src/templates/marker_group.js" /* webpackChunkName: "component---src-templates-marker-group-js" */),
  "component---src-templates-marker-js": () => import("./../../../src/templates/marker.js" /* webpackChunkName: "component---src-templates-marker-js" */),
  "component---src-templates-partner-js": () => import("./../../../src/templates/partner.js" /* webpackChunkName: "component---src-templates-partner-js" */),
  "component---src-templates-product-brand-js": () => import("./../../../src/templates/product_brand.js" /* webpackChunkName: "component---src-templates-product-brand-js" */),
  "component---src-templates-product-group-js": () => import("./../../../src/templates/product_group.js" /* webpackChunkName: "component---src-templates-product-group-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-support-group-js": () => import("./../../../src/templates/support_group.js" /* webpackChunkName: "component---src-templates-support-group-js" */),
  "component---src-templates-support-js": () => import("./../../../src/templates/support.js" /* webpackChunkName: "component---src-templates-support-js" */),
  "component---src-templates-symptom-group-js": () => import("./../../../src/templates/symptom_group.js" /* webpackChunkName: "component---src-templates-symptom-group-js" */),
  "component---src-templates-symptom-js": () => import("./../../../src/templates/symptom.js" /* webpackChunkName: "component---src-templates-symptom-js" */)
}

